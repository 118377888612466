
import React from "react";
import { Row } from "react-bootstrap";
import "./Footer.css";
// import { ReactLogo, BootstrapLogo } from "../../assets/images";

const Footer = () => {
    return (
        <Row id="footer">
            {/* <h4>This site was powered by: </h4>
            <Image src={ReactLogo} alt="react-logo"/>
            <Image src={BootstrapLogo} alt="bootstrap-logo"/> */}
            <footer>Copright ©️ 2023 by Jacob Cuison</footer>
        </Row>
    );
};

export default Footer;