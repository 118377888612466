
import React from "react";
import { Navbar, Nav, NavItem } from "react-bootstrap";
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import "./NavigationBar.css";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";

const NavigationBar = () => {

    return (
        <Navbar expand="lg" className="my-navbar" fixed="top" collapseOnSelect>
            <Navbar.Brand href="#">Jacob Cuison</Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Toggle aria-controls="page-navbar" />
            <NavbarCollapse id="page-navbar">
                <Nav className="mx-auto">
                    <Nav.Link href="#about">About Me</Nav.Link>
                    <Nav.Link href="#projects">Projects</Nav.Link>
                    <Nav.Link href="#education">Education</Nav.Link>
                    <Nav.Link href="#contact">Contact Me</Nav.Link>
                </Nav>
                <Nav className="ml-auto">
                    <NavItem className="social-icons">
                        <a href="https://www.linkedin.com/in/jacob-cuison/">
                            <FaLinkedin className="social-icon"/>
                        </a>
                    </NavItem>
                    <NavItem className="social-icons">
                        <a href="https://github.com/perpyderp">
                            <FaGithub className="social-icon"/>
                        </a>
                    </NavItem>
                </Nav>
            </NavbarCollapse>
        </Navbar>

    );
};

export default NavigationBar;