
import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import profileImage from "../../assets/images/profile-img.jpg";
import "./About.css";
import Experience from "../Experience/Experience";

const About = () => {
    return (
        <Container fluid id="about">
            <Row>
            <Col className="summary" md={6}>
                <h1>ABOUT ME</h1>
                <Image src={profileImage} fluid className="rounded-circle profile-image"/>
                <p>I'm Software Engineer currently working as a Digital Operations Coordinator for NBCUniversal. I have a passion for web development, game development/design, 
                and building things. Since childhood, I've always been interested in technology, especially video games.
                <br></br><br></br>
                Web development for me is a way for me to express my interests and talents. Designing beautiful interfaces enhancing users' experience acheiving the objective of 
                the site is what drives me to optimize and learn more what makes sites great and what can still be improved.
                </p>
            </Col>
            <Col md={6}>
                <Experience skill="Java" yearsExperience={4} experience={9}/>
                <Experience skill="JavaScript" yearsExperience={2} experience={7}/>
                <Experience skill="React" yearsExperience={2} experience={6}/>
                <Experience skill="CSS" yearsExperience={2} experience={6}/>
                <Experience skill="Python" yearsExperience={2} experience={5}/>
                <Experience skill="HTML5" yearsExperience={2} experience={5}/>
                <Experience skill="Spring Boot" yearsExperience={2} experience={5}/>
            </Col>
            </Row>
        </Container>
    );
};

export default About;