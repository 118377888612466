
import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap"
import "./Projects.css"
import { Giga, FullLobbyLogo, LevelUpLogo, CongressConnectionLogo } from "../../assets/images";
import { ButtonGroup } from "react-bootstrap/esm";

const Projects = () => {
  // Define your projects data
  const projects = [
    {
      title: 'Ark: Survival Evolved API',
      skills: ["devicon-react-original colored", "devicon-express-original-wordmark", "devicon-nodejs-plain-wordmark colored", "devicon-mongodb-plain-wordmark colored"],
      description: 'ARK API is a RESTful API that gives detailed information on many objects related to the survival game ARK Survival Evolved. This includes the game\'s events, creatures, egg types, etc.',
      img: Giga,
      demoURL: 'https://ark-api.vercel.app/',
      codeURL: 'https://github.com/perpyderp/ARK-REST-API'
    },
    {
      title: 'Congress Connection',
      skills: ["devicon-react-original colored", "devicon-spring-plain-wordmark colored", "devicon-mysql-plain colored"],
      description: 'Congress Connection is a fully stacked web application meant to inform the public about the latest political news, updates, etc. Users are able to search up politicians in their nearby area and their contact information, register and save their favorite politicians and news articles.',
      img: CongressConnectionLogo,
      demoURL: '',
      codeURL: 'https://github.com/SagarPatel201/Congress-Connection'
    },
    {
      title: 'Full Lobby: Gamer Social Media Full Stack Web Application',
      skills: ["devicon-react-original colored", "devicon-spring-plain-wordmark colored", "devicon-mysql-plain colored", "devicon-bootstrap-plain colored"],
      description: 'A fully stacked web application powered by many different skills/frameworks where gamers get to interact and showcase their favorite games and statistics from those games. Posts can be created for gamers to share their gaming experiences.',
      img: FullLobbyLogo,
      demoURL: '',
      codeURL: 'https://github.com/perpyderp/full-lobby'
    },
    {
      title: 'Social Web Game: \'Level Up\'',
      skills: ["devicon-html5-plain colored", "devicon-css3-plain colored", "devicon-javascript-plain colored"],
      description: 'A social game where people get to collaborate and contribute to completing quests all through their browser.',
      img: LevelUpLogo,
      demoURL: '',
      codeURL: 'https://github.com/perpyderp/levelup'
    }
  ];


  

  return (
      <Container fluid id="projects" className="">
      <div className="projects-wrapper">
        <h2 className="text-center mb-5">PROJECTS</h2>
        <Row className="mx-5">
          {projects.map((project, index) => (
            <Col className="project-card py-2 px-3" key={index} md={4}>
              <Col className="m-1">
                <img src={project.img} alt={project.title + "-img"}></img>
                
                <h3>{project.title}</h3>
                <Container className="h4 project-skills">Skills: 
                  {project.skills.map((skill, index) => {
                    return(
                      <i key={index} className={skill + " px-2 project-skill"}/>
                    )
                  })}
                </Container>
                <p>{project.description}</p>
                <ButtonGroup aria-label="project buttons">
                  {project.demoURL.length !== 0 ? 
                    <Button className="demo-button" href={project.demoURL}>Demo</Button>
                    : null
                  }
                  {project.codeURL.length !== 0 ? 
                    <Button className="code-button" href={project.codeURL}>Code</Button>
                    : null
                  }
                </ButtonGroup>
              </Col>
            </Col>
          ))}
        </Row>
      </div>
      </Container>
  );
};

export default Projects;