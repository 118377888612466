
import "./Header.css";
import { Container, Row, Col } from "react-bootstrap";
import Typewriter from "typewriter-effect";
import NavigationBar from "../NavigationBar/NavigationBar";

const Header = () => {
    return ( 
        
      <Container id="home" fluid>
      <NavigationBar />
        <Row className="intro align-items-center">
          <Col xs={12} md={12} xl={12}>
            <div className="intro-text">
              <Typewriter
                options={{
                  loop: true
                }}
                onInit={(typewriter) => {
                  typewriter
                  .typeString("Hello, my name is <strong>Jacob Cuison</strong>!")
                  .pauseFor(1000)
                  .deleteAll()
                  .typeString("Welcome to my portfolio!")
                  .start();
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
        
    );
};

export default Header;