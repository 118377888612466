
import React, { useState } from "react";
import { Container, Col, Row, Form, Button } from 'react-bootstrap';
import emailjs from '@emailjs/browser'
import validator from "validator";
import "./Contact.css";


const Contact = () => {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });

  const [emailSuccess, setEmailSuccess] = useState(false);
  const [emailFailed, setEmailFailed] = useState(false);
  const serviceID = process.env.REACT_APP_SERVICE_ID === undefined ? process.env.REACT_APP_SERVICE_ID : process.env.SERVICE_ID;
  const publicKey = process.env.REACT_APP_PUBLIC_KEY === undefined ? process.env.REACT_APP_PUBLIC_KEY : process.env.PUBLIC_KEY;
  const templateID = process.env.REACT_APP_TEMPLATE_ID === undefined ? process.env.REACT_APP_TEMPLATE_ID : process.env.TEMPLATE_ID;

  const [errors, setErrors] = useState([]);

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value
    }));
  }

  const sendMessage = (e) => {
    e.preventDefault();
    setErrors([]);
    if(!validator.isEmail(formData.email)) {
      setErrors((prevErrors) => [...prevErrors, "You must enter a valid email"]);
    }
    if(!formData.name) {
      setErrors((prevErrors) => [...prevErrors, "You must enter a valid name"]);
    }
    if(!formData.message) {
      setErrors((prevErrors) => [...prevErrors, "You must enter a message"]);
    }
    if(errors === undefined || errors.length === 0) {
      
      emailjs.send(serviceID, templateID, formData, publicKey)
      .then(function(response) {
        setEmailSuccess(true);
      }, function(error) {
        setEmailFailed(true);
        console.log(error);
      });
    }
  }


  return (
    <Container fluid id="contact" className="align-items-center">
      <Row>
        <Col lg={6} className="mx-auto">
        <h3>CONTACT ME</h3>
          <Form onSubmit={sendMessage}>
            {
              errors ? errors.map((error, index) => {
                return (
                  <p key={index}>{error}</p>
                )
              }) : null
            }
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter your name" name="name" value={formData.name} onChange={handleChange}/>
            </Form.Group>
            <Form.Group controlId="formEmail" >
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Enter your email" name="email" value={formData.email} onChange={handleChange}/>
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Enter your message" name="message" value={formData.message} onChange={handleChange}/>
            </Form.Group>
            <Button variant="primary" type="submit">
            Submit
            </Button>
          </Form>
          { emailSuccess ?
              <div>Thank you for the message! We'll be in touch 😊</div>
              : null
          }
          { emailFailed ?
              <div>Email message failed. Please try again</div>
              : null
          }
        </Col>
      </Row>
    </Container>

  );
};

export default Contact;