
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Projects from "./components/Projects/Projects";
import Header from './components/Header/Header';
import Footer from "./components/Footer/Footer";
import Education from "./components/Education/Education";
import "./App.css";
import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <div className="mx-0 px-0">
      <Header />
      <About />
      <Projects />
      <Education />
      <Contact />
      <Footer />
      <Analytics />
    </div>
  );
}

export default App;
