
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { MCCLogo, RutgersLogo } from "../../assets/images";
import "./Education.css";

const Education = (props) => {
    return (
      <Container fluid id="education">
        <h2>Education</h2>
        <Row>
          <Col md={6} className="text-center">
            <img src={RutgersLogo} alt="RU Logo" className="education-img" />
            <h4>Rutgers University - New Brunswick</h4>
            <p>Bachelor's Degree in Computer Science</p>
            <p>September 2020 - May 2022</p>
          </Col>
          <Col md={6} className="text-center">
            <img src={MCCLogo} alt="MCC Logo" className="education-img" />
            <h4>Middlesex Community College</h4>
            <p>Associate's Degree in Computer Science</p>
            <p>September 2018 - August 2020</p>
          </Col>
        </Row>
      </Container>
    );
}

export default Education;