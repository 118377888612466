import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Experience.css";

const Experience = (experienceProps) => {

  const experienceBarFill = useRef(null);
  const max_percentage = (experienceProps.experience / 10) * 100;
  const [XP_Bar_Width, setWidth] = useState(0);
  const [percent, setPercent] = useState(0);

  useEffect(() => {

    const wasAnimated = experienceBarFill.current;

    const options = {
      rootMargin: "0px",
      threshold: 0.5 // The observer will trigger when the element is 50% visible
    };
    
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setWidth(max_percentage);
        }
      });
    }, options);
    
    observer.observe(wasAnimated);

    return () => observer.unobserve(wasAnimated);

  }, [max_percentage]);

  useEffect(() => {
    if (percent < max_percentage) {
      const intervalID = setInterval(() => {
        setPercent((prevPercent) => prevPercent + 1);
      }, 15);
      return () => clearInterval(intervalID);
    }
  }, [percent, max_percentage, XP_Bar_Width]);

  const usePluralYear = experienceProps.yearsExperience > 1 ? "years" : "year";
  return (
    
    <Container id="experience-bar-container">
      <Row>
        <Col className="skill" md={2} lg={2}>
          <h4>{experienceProps.skill}</h4>
        </Col>
        <Col className="experience-bar" md={10} lg={10}>
          <span>{percent}%</span>
          <div
            className="experience-bar-fill"
            ref={experienceBarFill}
            style={{ width: `${XP_Bar_Width}%` }}
          ></div>
        </Col>
      </Row>
      <Row>
        <p>{experienceProps.yearsExperience} {usePluralYear} of experience</p>
      </Row>
        <div className="box">
        </div>
    </Container>
  );
};

export default Experience;
